import React from 'react'
import { Link } from 'gatsby'

const Navbar = () => (
  <div className="z-1 relative bg-white">
    <div className="relative z-10 shadow">
      <div className="max-w-screen-lg mx-auto flex justify-between items-center py-5 sm:py-4 md:space-x-10">
        <div>
          <Link to="/" className="flex">
            <img className="h-6 w-auto" src="https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2FLOGO_BLUE_1_BLUE_2.png?alt=media&token=c1e5f041-7759-4698-a9ee-216c3fcf292e" alt="Workflow" />
          </Link>
        </div>
        <a
          href="https://www.column.us"
          target="_blank"
          className="text-blue-800 hover:text-blue-700 flex items-center pr-6"
        >
          <span className="h-full mt-1">Column Homepage</span><span className="ml-2 text-2xl">›</span>
        </a>
      </div>
    </div>
  </div>
);

export default Navbar;
