import React from 'react'

const Footer = () => (
  <div
    className="bg-white border-t z-1"
    style={{
      position: 'absolute',
      width: '100%',
      bottom: 0,
    }}
  >
    <div className="max-w-screen-xl mx-auto py:2 lg:py-6 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div className="mt-8 md:mt-0 md:order-1">
        <p className="text-center text-base leading-6 text-gray-400">
          &copy; 2020 Column, PBC. All rights reserved.
        </p>
      </div>
    </div>
  </div>

);

export default Footer
